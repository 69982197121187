import React, { useEffect, useState } from "react";
import { Card, Table, Button, Modal, Form, Input, Select, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { createCar, deleteCar, editCar, getAllCarType, getAllCars, getUnallocatedDrivers } from "./store/action_creator";
const { Option } = Select
const { Search } = Input

const Car = () => {
    const allCars = useSelector((state) => state?.car?.cars)
    const carsType = useSelector((state) => state?.car?.carsType)
    const allUacDrivers = useSelector((state) => state?.car?.unAllocatedDrivers)

    const [types, setcarType] = useState([])
    const [editingcar, setEditingCar] = useState(null);
    const [visible, setVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [searchText, setSearchText] = useState('')
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        setLoading(true)
        dispatch(getAllCars())
          .then(() => { 
            dispatch(getAllCarType());
            dispatch(getUnallocatedDrivers());
        })
          .finally(() => setLoading(false));
      }, [dispatch]);

    useEffect(() => {
        if (editingcar) {
            form.setFieldsValue(editingcar);
        }
    }, [editingcar, form]);

    useEffect(() => {
        if (carsType) {
            setcarType(carsType)
        }
    }, [carsType])

    useEffect(() => {
        if(searchText.length>2) {
          const propertiesToSearch = ["model", "make", "type","registrationNumber", "driverName"];
          const lowercaseSearch = searchText.toLowerCase();
          
          const filteredArray = allCars.filter(item => {
            for (const prop of propertiesToSearch) {
                const lowercaseValue = item[prop] ? item[prop].toString().toLowerCase() : '';
        
              if (lowercaseValue.includes(lowercaseSearch)) {
                return true; 
              }
            }
            return false;
          });
          setFilteredData(filteredArray)
        }else {
          setFilteredData(allCars)
        }
      }, [searchText, allCars]);

    const handleCreateCar = () => {
        setEditingCar(null);
        setIsEditMode(false);
        setVisible(true);
    }

    const handleEditCar = (record) => {
        console.log(record,'handle eriri');
        setEditingCar(() => ({
            ...record,
            driverId : '',
            driverName: ''
          } 
        ));

        setIsEditMode(true);
        setVisible(true);
    };

    const handleDeleteCar = (record) => {
        setLoading(true)
        dispatch(deleteCar(record.carId)).then(res => {
          dispatch(getAllCars(res)).then(res => {
            setLoading(false)
          })
        })
      }

    const handleCancel = () => {
        setEditingCar(null);
        form.resetFields();
        setVisible(false);
    };

    const handleInputChange = (key, value) => {
        setEditingCar((prevValues) => ({
            ...prevValues,
            [key]: value,
          } 
        ));

        if(key === 'driverId') {
            let selectedDriver = allUacDrivers.find(item => item.userId === value)
            if(selectedDriver) {
                setEditingCar((prevValues) => ({
                    ...prevValues,
                    driverName : selectedDriver.name
                  } 
                ));
            }
        }
    };

    // const handleFormSubmit = async (values) => {
    //     try {   
    //       setLoading(true); 
    //       setVisible(false);
    //       form.resetFields(); 
      
    //       if (isEditMode && editingcar) {
    //         let formValues = JSON.parse(JSON.stringify(editingcar))
    //         if (formValues.driverName === "") {
    //             formValues.driverName = null;
    //         }
    //         console.log(formValues,'edittttt');
    //         let selectedCar = types.find(item => item.carTypeId === formValues.carTypeId)
    //         formValues['type'] = selectedCar.type
    //         formValues['carStatus'] = selectedCar.carTypeStatus;
    //         console.log(formValues,'formvalye');
    //         await dispatch(editCar(formValues))
    //       } else {
    //         let formValues = JSON.parse(JSON.stringify(values))
    //         let formValuestwo = JSON.parse(JSON.stringify(editingcar))
    //         console.log(formValuestwo,'fomrwnnnn');
    //         const drivername = formValuestwo.driverName
    //         let selectedCar = types.find(item => item.carTypeId === formValues.carTypeId)
    //         formValues['type'] =  selectedCar.type
    //         formValues['carStatus'] = selectedCar.carTypeStatus;
    //         formValues['driverName'] = drivername;
    //         console.log(formValues,'formvakyee');
    //         await dispatch(createCar(formValues));
    //       }
      
    //       setLoading(false);
    //     } catch (error) {
    //       message.error(error)
    //       setLoading(false); 
    //     }
    //   };



    const handleFormSubmit = async (values) => {
        try {
            setLoading(true);
            setVisible(false);
            form.resetFields();
    
            let formValues;
            if (isEditMode && editingcar) {
                formValues = JSON.parse(JSON.stringify(editingcar));
                if (formValues.driverName === "") {
                    formValues.driverName = null;
                }
            } else {
                formValues = JSON.parse(JSON.stringify(values));
            }
    
            const selectedCar = types.find(item => item.carTypeId === formValues.carTypeId);
            formValues.type = selectedCar.type;
            formValues.carStatus = selectedCar.carTypeStatus;
    
            if (isEditMode && editingcar) {
                // console.log(formValues,'edit car');
                await dispatch(editCar(formValues));
            } else {
                // console.log(formValues,'create car');
                await dispatch(createCar(formValues));
            }
    
            setLoading(false);
        } catch (error) {
            message.error(error);
            setLoading(false);
        }
    };
    
    const cancel = () => {
        console.log('Deletion canceled.');
    };


    const columns = [
       
        {
            title: 'Car Model',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Company',
            dataIndex: 'make',
            key: 'make',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Registration Number',
            dataIndex: 'registrationNumber',
            key: 'registrationNumber',
        },
        {
            title: 'Driver',
            dataIndex: 'driverName',
            key: 'driverName',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    <EditOutlined style={{ fontSize: '16px', color: '#08c' }} onClick={() => handleEditCar(record)} />
                    <Popconfirm title="Are you sure to delete this car?" onConfirm={() => handleDeleteCar(record)} onCancel={cancel} okText="Yes" cancelText="No">
                        <DeleteOutlined style={{ fontSize: '16px', color: '#D00', paddingLeft: '10px' }} />
                    </Popconfirm>
                </span>
            ),
        }
    ];

    
    return (
        <Card
            title="Cars"
            extra={
            <span style={{display: 'flex', flexFlow: 'row', marginTop: '10px'}}>
                <Button type="primary" onClick={() => handleCreateCar()}>Create Car</Button>
                <Search placeholder="Search" enterButton onChange={(e) => setSearchText(e.target.value)}/>
            </span>
            }>
            <Table className="gx-table-responsive" columns={columns} dataSource={filteredData} loading={loading} bordered={true}/>
            <Modal
                title={isEditMode ? "Edit Car" : "Create Car"}
                visible={visible}
                onCancel={handleCancel}
                footer={null}
                className="modalStyle"
                >

                <Form onFinish={handleFormSubmit} form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 20 }}>

                    <Form.Item
                        label="Car Model"
                        name="model"
                        rules={[{ required: true, message: 'Please enter the name' }]}>
                        <Input placeholder="Enter the car model" onChange={(e) => handleInputChange("model", e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        label="Company"
                        name="make"
                        rules={[{ required: true, message: 'Please enter the company' }]}>
                        <Input placeholder="Enter the car company" onChange={(e) => handleInputChange("make", e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        name="seats"
                        label="Seat"
                        rules={[{ required: true, message: 'Please type no: of seats!' }]}>
                        <Input placeholder="Enter the car company" onChange={(e) => handleInputChange("seats", e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        label="Registration Number"
                        name="registrationNumber"
                        rules={[{ required: true, message: 'Please enter the car registration number' }]}>
                        <Input placeholder="Enter the car registration number" onChange={(e) => handleInputChange("registrationNumber", e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        name="carTypeId"
                        label="Type"
                        rules={[{ required: true, message: 'Please select Type!' }]}>
                        <Select placeholder="select your type" onChange={(value) =>  handleInputChange("carTypeId", value)}>
                            {types.map((type, index) => (
                                <Option key={index} value={type.carTypeId}>
                                    {type.type}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {isEditMode && (
                        <Form.Item
                            name="driverId"
                            label="Driver"
                            // rules={[{ required: true, message: 'Please select Driver!' }]}>
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value !== null || getFieldValue('driverId') === null) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Please select Driver!'));
                                    },
                                }),
                            ]}>
                            <Select placeholder="select your type" defaultValue={''} onChange={(value) => handleInputChange("driverId", value)}>
                            <Option key="unassigned" value={null} style={{ color: 'lightcoral' }}>
                                    UNASSIGN DRIVER
                                </Option>
                                <Option key={''} value={''}>
                                    Select
                                </Option>
                                {allUacDrivers && allUacDrivers.map((driver, index) => (
                                    <Option key={index} value={driver.userId}>
                                        {driver.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}


                    <Form.Item style={{ marginTop: '40px', marginLeft: '150px' }}>
                        <Button type="danger" onClick={handleCancel}>
                            cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {isEditMode ? "Update" : "Create"}
                        </Button>
                    </Form.Item>
                    
                   
                </Form>

            </Modal>
        </Card>
    )
}

export default Car;


