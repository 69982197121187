import React, { useEffect, useState } from "react";
import moment from 'moment';
import { Card, Table, Button, Modal, Form, Input, Popconfirm, message, Select, Menu, Dropdown, DatePicker, AutoComplete } from "antd";
import { EditOutlined, DeleteOutlined, DownOutlined, CheckCircleOutlined, MessageOutlined } from '@ant-design/icons';
import { createRide, deleteRide, distanceUrl, getAllRides, getGeoCode, locationApi, otpRide, sendNotification, updateRide } from "./srore/action-creator";
import { useDispatch, useSelector } from "react-redux";
import { getAllDriver } from "../Driver/store/action-creator";
import { getAllCarType } from "../Car/store/action_creator";
import { getAllUsers } from "../User/store/action-creator";
const { Option } = Select
const { Search } = Input
const { RangePicker } = DatePicker;



const Ride = () => {
    const allUsers = useSelector((state) => state?.user?.users)
    const allRides = useSelector((state) => state?.ride?.rides)
    const alldrivers = useSelector((state) => state?.driver?.drivers)
    const carsType = useSelector((state) => state?.car?.carsType)
    const [editingride, setEditingRide] = useState(null);
    const [visible, setVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [loading, setLoading] = useState(false)
    const [driverName, setDriverName] = useState([])
    const [carsNames, setCarNames] = useState([])
    const [searchText, setSearchText] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuVisibleFilter, setMenuVisibleFilter] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [ReFilter, setReFilter] =  useState([])
    const [ScheduledReFilter, setScheduledReFilter] =  useState([])
    const [DateReFilter, setDateReFilter] =  useState([])

    const [suggestions, setSuggetsions] = useState([])
    const [selectedPickupLocation, setSelectedPickupLocation] = useState('');
    const [selectedDropLocation, setSelectedDropLocation] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('')
    const [pickupAddress, setPickupAddress] = useState('');
    const [dropoffAddress, setDropoffAddress] = useState('');
    const [showVerifyButton, setShowVerifyButton] = useState(false);
    const [pickaddress, setPickAddress] = useState([])
    const [dropaddress, setDropAddress] = useState([])
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const dispatch = useDispatch()
    const [form] = Form.useForm();


    useEffect(() => {
        setLoading(true);
        Promise.all([
            dispatch(getAllRides()),
            dispatch(getAllDriver()),
            dispatch(getAllCarType()),
            dispatch(getAllUsers()),

        ]).finally(() => setLoading(false));
    }, [dispatch]);

    useEffect(() => {
        if (alldrivers) {
            setDriverName(alldrivers)
        }
    }, [alldrivers])

    useEffect(() => {
        if (carsType) {
            setCarNames(carsType)
        }
    }, [carsType])


    useEffect(() => {
        if (searchText.length >= 2) {
            const propertiesToSearch = ["bookingRef", "passengerName", "driverName", "carType", "pickupLocationAddress", "dropoffLocationAddress",
                "rideStatus", "paymentMode", "fare", "tax", "rideEndTimestamp", "rideStartTimestamp", 'isBookedByAdmin', "distanceInKm", "remark"];
            const lowercaseSearch = searchText.toLowerCase();
            const filteredArray = allRides.filter(item => {
                
                for (const prop of propertiesToSearch) {
                    const propValue = item[prop]

                    if (prop === 'isBookedByAdmin') {
                        const bookedByValue = item[prop] === true ? 'Admin' : 'App';
                        if (bookedByValue.toLowerCase().includes(lowercaseSearch)) {
                            return true;
                        }
                    }

                    if (propValue && propValue.toString().toLowerCase() === lowercaseSearch) {
                        return true;
                    }
                }
                return false;
            });
            setFilteredData(filteredArray)
        } else {
            setFilteredData(allRides)
            setReFilter(allRides)
            setScheduledReFilter(allRides)
            setDateReFilter(allRides)
        }
    }, [searchText, allRides]);

    useEffect(() => {
        if (editingride) {
            form.setFieldsValue(editingride);
        }
    }, [editingride, form]);

    const handleCreateRide = () => {
        setEditingRide(null);
        setIsEditMode(false);
        setVisible(true);
    }

    const handleEditRide = (record) => {
        let editedRecord = { ...record };
        if (record && record.rideScheduledStartTimestamp) {
            editedRecord.rideScheduledStartTimestamp = moment(record.rideScheduledStartTimestamp);
        }

        setEditingRide(editedRecord);
        setIsEditMode(true);
        setVisible(true);
    };

    const handleDeleteRide = (record) => {
        setLoading(true)
        dispatch(deleteRide(record.rideId)).then(res => {
            dispatch(getAllRides(res)).then(res => {
                setLoading(false)
            })
        })
    }

    const handleConfirmOtp = (record) => {
        dispatch(otpRide(record))
    }

    const handleCancel = () => {
        setEditingRide(null);
        form.resetFields();
        setVisible(false);
    };

    const handleSuggestions = async (value) => {
        dispatch(locationApi(value)).then(res => {
            setSuggetsions(res)
        })
    }

    const handleInputChange = async (key, value) => {
        setEditingRide((prevValues) => ({
            ...prevValues,
            [key]: value
        }));


        if (key === 'pickup_place_id') {
            let suggestion = suggestions.find(item => item.place_id === value)
            setSelectedPickupLocation(suggestion?.place_id)
            setPickupAddress(value);
            setPickAddress(suggestion?.description)
            setEditingRide((prevValues) => ({
                ...prevValues,
                pickupLocationAddress: suggestion.description
            }));
        }

        if (key === 'dropoff_place_id') {
            let suggestion = suggestions.find(item => item.place_id === value)
            setSelectedDropLocation(suggestion?.place_id)
            setDropoffAddress(value);
            setDropAddress(suggestion?.description)
            setEditingRide((prevValues) => ({
                ...prevValues,
                dropoffLocationAddress: suggestion.description
            }));
        }
  

        if (key === 'driverId') {
            let suggestion = driverName.find(item => item.userId === value)
            setEditingRide((prevValues) => ({
                ...prevValues,
                driverName: suggestion.name
            }));
        }

        if (key === 'userId') {
            let suggestion = allUsers.find(item => item.userId === value)
            setEditingRide((prevValues) => ({
                ...prevValues,
                passengerName: suggestion.name,
            }));
        }
    };

    useEffect(() => {
        if (pickupAddress && dropoffAddress) {
          setShowVerifyButton(true);
        } else {
          setShowVerifyButton(false);
        }
      }, [pickupAddress, dropoffAddress]);


    const handleMenuClick = (e) => {
        setMenuVisible(false);

        const isAdvancedBooking = e.key === 'scheduled';

        const filteredDataed = ScheduledReFilter.filter((item) => {
            return item.isAdvancedBooking === isAdvancedBooking;
        });

        setFilteredData(filteredDataed);
        setSelectedOption(isAdvancedBooking ? 'Scheduled' : 'Instant booking')

    }

    const handleMenuClickFilter = (e) => {
        setMenuVisible(false)

        const selectedOption = e.key;
        setSelectedFilter(selectedOption);

        if(selectedOption === 'show') {
            setFilteredData(ReFilter)
        } else {
            const filteredDatass = ReFilter.filter((item) => {
                return item.rideStatus === e.key
                
            })
            setFilteredData(filteredDatass);
        }
    }

    const handleDateRangeChange = (dates) => {
        setSelectedDateRange(dates);
      };

      const handleSearchClick = () => {        
        const filteredDataff = DateReFilter.filter((item) => {
        const itemDate = new Date(item.rideScheduledStartTimestamp); 
        const [startDate, endDate] = selectedDateRange;
        return itemDate >= startDate && itemDate <= endDate;

        });
        setFilteredData(filteredDataff);
      };  

    const cancel = () => {
        console.log('Deletion canceled.');
    };

    const getGeoCodes = async (id) => {
        let resp = await dispatch(getGeoCode(id)).then(res => {
            return res
        })
        return resp
    }

    const distanceUrll = async (pickupLocationLatitude, pickupLocationLongitude, dropoffLocationLatitude, dropoffLocationLongitude) => {
        let respo = await dispatch(distanceUrl(pickupLocationLatitude, pickupLocationLongitude, dropoffLocationLatitude, dropoffLocationLongitude)).then(res => {
            return res
        })
        return respo
    }

    const handleVerifyButtonClick = () => {
        if(selectedPickupLocation && selectedDropLocation ){
            const mapsURL = `https://www.google.com/maps/dir/?api=1&origin=${pickaddress}&destination=${dropaddress}&key=AIzaSyCaMj8Gm5QB6aXTLeC4NJzItjIU4kmvXUc`;
            window.open(mapsURL, '_blank')
        } else {
            console.error('Some required values are missing.');
        }
    }

    const handleSendNotification = (record) => {
        dispatch(sendNotification(record))
    }

    const currentDateTime = moment();
    const defaultStartDate = moment(currentDateTime).add(6, 'hours');

    const handleFormSubmit = async (values) => {

        try {
            if (isEditMode && editingride) {

                setLoading(true);
                setVisible(false);
                form.resetFields();

                let formValues = JSON.parse(JSON.stringify(editingride))
                let selectedCar = driverName.find(item => item.userId === formValues.driverId)
                let selectedDriver = carsNames.find(item => item.type === formValues.carType)

                formValues['carId'] = selectedDriver.carId
                //                formValues['carId'] = selectedDriver.carTypeId

                formValues['driverName'] = selectedCar.name
                formValues['rideScheduledStartTimestamp'] = moment(formValues['rideScheduledStartTimestamp']).format('YYYY-MM-DDTHH:mm:ss')

                try {
                    await dispatch(updateRide(formValues));
                    await dispatch(getAllRides());
                    setLoading(false);
                } catch (error) {
                    message.error(error);
                    setLoading(false);
                }
            } else {

                setLoading(true);
                setVisible(false);
                form.resetFields();

                let formValues = JSON.parse(JSON.stringify(values))
                let selctedPassengerId = allUsers.find(item => item.name === formValues.passengerName )

                formValues['userId'] = selctedPassengerId.userId
                formValues['rideScheduledStartTimestamp'] = moment(formValues['rideScheduledStartTimestamp']).format('YYYY-MM-DDTHH:mm:ss')


                const PickupPlaceId = selectedPickupLocation;
                const DropOffPlaceId = selectedDropLocation

                let pickup = await getGeoCodes(PickupPlaceId)
                let dropoff = await getGeoCodes(DropOffPlaceId)

                const newRide = {
                    ...formValues,
                    pickupLocationAddress: formValues.pickupLocationAddress,
                    dropoffLocationAddress: formValues.dropoffLocationAddress,
                    pickupLocationLatitude: pickup["results"][0]["geometry"]["location"]["lat"],
                    pickupLocationLongitude: pickup["results"][0]["geometry"]["location"]["lng"],
                    dropoffLocationLatitude: dropoff["results"][0]["geometry"]["location"]["lat"],
                    dropoffLocationLongitude: dropoff["results"][0]["geometry"]["location"]["lng"],
                    isAdvancedBooking: 'true',
                    isBookedByAdmin: 'true',
                };

                let distance = await distanceUrll(newRide.pickupLocationLatitude, newRide.pickupLocationLongitude, newRide.dropoffLocationLatitude, newRide.dropoffLocationLongitude)

                let distancebykm = distance["routes"][0]["legs"][0]["distance"]["text"]
                const parts = distancebykm.split(" ");
                const distanceInKms = parseInt(parts[0].replace(",", ""), 10);
                let durationbykm = distance["routes"][0]["legs"][0]["duration"]["text"]

                const newtwo = {
                    ...newRide,
                    distanceInKm: distanceInKms,
                    rideEta: durationbykm
                }
               
                await dispatch(createRide(newtwo));
                setTimeout(async () => {
                    try {
                        setLoading(false);
                    } catch (error) {
                        message.error(error);
                        setLoading(false);
                    }
                    await dispatch(getAllRides());
                }, 6000);

            }
        } catch (error) {
            message.error(error)
            setLoading(false);
        }
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="scheduled">Scheduled</Menu.Item>
            <Menu.Item key="instant">Instant booking</Menu.Item>
        </Menu>
    );

    const filtermenu = (
        <Menu onClick={handleMenuClickFilter}>
            <Menu.Item key="show">Show All Status</Menu.Item>
            <Menu.Item key="accepted">Accepted</Menu.Item>
            <Menu.Item key="expired">Expired</Menu.Item>
            <Menu.Item key="requested">Requested</Menu.Item>
            <Menu.Item key="cancelled">Cancelled</Menu.Item>
            <Menu.Item key="completed">Completed</Menu.Item>
            <Menu.Item key="ontrip">On-trip</Menu.Item>
            <Menu.Item key="ended">Ended</Menu.Item>
            <Menu.Item key="arrived">Arrived</Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: 'Booking Ref',
            dataIndex: 'bookingRef',
            key: 'bookingRef',
            fixed: 'left'
        },
        {
            title: 'Passenger Name',
            dataIndex: 'passengerName',
            key: 'passengerName',
        },
        {
            title: 'Passenger Mobile',
            dataIndex: 'userMibile',
            key: 'userMibile',
        },
        {
            title: 'Driver Name',
            dataIndex: 'driverName',
            key: 'driverName',
        },
        {
            title: 'Driver Mobile',
            dataIndex: 'driverMobile',
            key: 'driverMobile',
        },
        {
            title: 'Car Type',
            dataIndex: 'carType',
            key: 'carType',
        },
        {
            title: 'Pickup Location',
            dataIndex: 'pickupLocationAddress',
            key: 'pickupLocationAddress',
        },
        {
            title: 'DropOff Location',
            dataIndex: 'dropoffLocationAddress',
            key: 'dropoffLocationAddress',
        },
        {
            title: 'Ride Status',
            dataIndex: 'rideStatus',
            key: 'rideStatus',
            render: text => (
                <span style={{ color: text === 'accepted' ? 'green' : text === 'completed' ? 'green' : text === 'cancelled' ? 'red' : text === 'expired' ? 'red' : text === 'requested' ? '#d6d600' : text === 'ontrip' ? 'green' : text === 'ended' ? 'green' : text === 'arrived' ? 'green' : '' }}>
                    {text === 'accepted' ? 'Accepted' : text === 'completed' ? 'Completed' : text === 'cancelled' ? 'Cancelled' : text === 'expired' ? 'Expired' : text === 'requested' ? 'Requested' : text === 'ontrip' ? 'On-Trip' : text === 'ended' ? 'Ended' : text === 'arrived' ? 'Arrived' : text}
                </span>
            )
        },
        {
            title: 'Payment Mode',
            dataIndex: 'paymentMode',
            key: 'paymentMode',
        },
        {
            title: 'Fare',
            dataIndex: 'fare',
            key: 'fare',
        },
        {
            title: 'Tax',
            dataIndex: 'tax',
            key: 'tax',
        },
        {
            title: 'Ride Booking Time',
            render: (_, record) => {
                const formattedDate = moment(record.rideBookingTimestamp).format("DD-MM-YYYY HH:mm:ss");
                return <span>{formattedDate}</span>
            }

        },
        {
            title: 'Ride Start Time',
            render: (_, record) => {
                const formattedDate = moment(record.rideScheduledStartTimestamp).format("DD-MM-YYYY HH:mm:ss");
                return <span>{formattedDate}</span>
            }
        },
        {
            title: 'Booked By',
            render: (_, record) => {
                const bookedBy = record.isBookedByAdmin === true ? 'Admin' : 'App';
                return <span>{bookedBy}</span>;
            }
        },
        {
            title: 'Distance in KM',
            dataIndex: 'distanceInKm',
            key: 'distanceInKm' 
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark'
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            render: (text, record) => (
                <span>
                    <EditOutlined style={{ fontSize: '16px', color: '#08c' }} onClick={() => handleEditRide(record)} />
                    <Popconfirm title="Are you sure to delete this user?" onConfirm={() => handleDeleteRide(record)} onCancel={cancel} okText="Yes" cancelText="No">
                        <DeleteOutlined style={{ fontSize: '16px', color: '#D00', paddingLeft: '10px' }} />
                    </Popconfirm>
                    <Popconfirm title="Are you sure want to send the ride otp" onConfirm={() => handleConfirmOtp(record)} onCancel={cancel} okText="Yes" cancelText="No">
                        <MessageOutlined style={{ fontSize: '16px', color: '#eb2f96', paddingLeft: '10px' }}/>
                    </Popconfirm>
                    <Popconfirm title="Are you sure want to send the ride otp" onConfirm={() => handleSendNotification(record)} onCancel={cancel} okText="Yes" cancelText="No">
                            <i className="icon icon-driver-steering"></i>
                    </Popconfirm>                   
                </span>
            ),
        }
    ];

    return (

        <Card
            title="Rides"
            extra={
                <span style={{ display: 'flex', flexFlow: 'row', marginTop: '10px' }}>

                    <RangePicker 
                        showTime    
                        defaultValue={[currentDateTime, defaultStartDate]} 
                        format={'YYYY:MM:DD HH:MM'} 
                        onChange={handleDateRangeChange}
                        style={{ marginRight: '14px', height: '38px', width: '-webkit-fill-available' }} />

                    <Button type="primary" onClick={handleSearchClick}>Search</Button>

                    <Button style={{ marginLeft: '390px' }} type="primary" onClick={() => handleCreateRide()}>Create Ride</Button>

                    
                    <Dropdown overlay={menu} visible={menuVisible} onVisibleChange={(visible) => setMenuVisible(visible)}>
                        <Button onClick={() => setMenuVisible(!menuVisible)} style={{ marginRight: '10px' }}>
                            {selectedOption || 'Scheduled/Booking'} <DownOutlined />
                        </Button>
                    </Dropdown>

                    <Dropdown  style={{ width: '200px' }}  overlay={filtermenu} visible={menuVisibleFilter} onVisibleChange={(visible) => setMenuVisibleFilter(visible)}>
                        <Button onClick={() => setMenuVisibleFilter(!menuVisibleFilter)} style={{ marginRight: '10px' }}>
                        {selectedFilter === 'show' ? 'Show All Status' : (selectedFilter ? selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1) : "Show All Status")} <DownOutlined />
                        </Button>
                    </Dropdown>

                    <Search
                        placeholder="Search"
                        enterButton
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </span>
            }
        >
            <Table className="gx-table-responsive" columns={columns} dataSource={filteredData} loading={loading} scroll={{ x: 3000 }} bordered={true} />
            <Modal
                title={isEditMode ? "Edit Ride" : "Create Ride"}
                visible={visible}
                onCancel={handleCancel}
                footer={null}
                className="modalStyle">

                <Form onFinish={handleFormSubmit} form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 20 }}>

                    {isEditMode && (
                        <Form.Item
                            label="Booking Ref"
                            name="bookingRef"
                            rules={[{ required: true, message: 'Please select the Booking ref' }]}
                        >
                            <Input placeholder="Enter the car model" onChange={(e) => handleInputChange("bookingRef", e.target.value)} />
                        </Form.Item>
                    )}

                    <Form.Item
                        label="Passenger name"
                        name="passengerName"
                        rules={[{ required: true, message: 'Please select the user' }]}
                    >
                        <Select
                            placeholder="Select your user"
                            onChange={(value) => handleInputChange("userId", value)}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                const optionText = option.children.toLowerCase();
                                const searchInput = input.toLowerCase();
                                const userId = option.value.toString(); 
                                return optionText.indexOf(searchInput) >= 0 || userId.indexOf(searchInput) >= 0;
                            }}
                        >
                            {allUsers.map((type, index) => (
                                <Option key={index} value={type.userId}>
                                    {`${type.name} - (${type.phoneNumber})`}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>  

                    {isEditMode && (
                    <Form.Item
                        label="Passenger Mobile"
                        name="userMibile"
                        rules={[{ required: true, message: 'Please select the passenger Mobile' }]}
                    >
                        <Input placeholder="Enter the passenger Mobile" onChange={(e) => handleInputChange("userMibile", e.target.value)} />
                    </Form.Item>
                    )}

                    {isEditMode && (
                        <Form.Item
                            label="Driver name"
                            name="driverName"
                            rules={[{ required: true, message: 'Please select the Driver' }]}>
                            <Select placeholder="Select your Driver" onChange={(value) => handleInputChange("driverId", value)}>
                                {alldrivers.map((type, index) => (
                                    <Option key={index} value={type.userId}>
                                        {type.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    {isEditMode && (
                        <Form.Item
                            label="Driver Mobile"
                            name="driverMobile"
                            rules={[{ required: true, message: 'Please select the Driver Mobile' }]}
                        >
                            <Input placeholder="Enter the Driver Mobile" onChange={(e) => handleInputChange("driverMobile", e.target.value)} />
                        </Form.Item>
                    )}

                    <Form.Item
                        label="Pickup Location"
                        name="pickupLocationAddress"
                        rules={[{ required: true, message: 'Please enter pickup location' }]}
                    >
                        <AutoComplete
                            placeholder="Enter your Pickup Location"
                            onSearch={(value) => handleSuggestions(value)}
                            onSelect={(value) => handleInputChange('pickup_place_id', value)}
                        >
                            {suggestions && suggestions.map((suggestion, index) => (
                                <AutoComplete.Option key={index} value={suggestion.place_id}>
                                    {suggestion.description}
                                </AutoComplete.Option>
                            ))}
                        </AutoComplete>
                    </Form.Item>

                    <Form.Item
                        label="DropOff Location"
                        name="dropoffLocationAddress"
                        rules={[{ required: true, message: 'Please enter dropoff location' }]}
                    >
                        <AutoComplete
                            placeholder="Enter your Dropoff Location"
                            onChange={(value) => handleSuggestions(value)}
                            onSelect={(value) => handleInputChange('dropoff_place_id', value, 'dropoffLocationAddress')}
                        >
                            {suggestions && suggestions.map((suggestion, index) => (
                                <AutoComplete.Option key={index} value={suggestion.place_id}>
                                    {suggestion.description}
                                </AutoComplete.Option>
                            ))}
                        </AutoComplete>
                    </Form.Item>

                    {showVerifyButton && ( 
                        <span  style={{marginLeft: '180px', marginTop: '-10px', marginBottom: '10px', display: 'block' , color: '#2129B6'}}
                         onClick={handleVerifyButtonClick}
                      
                         type="primary"><CheckCircleOutlined />  VERIFY THIS ADDRESS</span>
                    )}

                    <Form.Item
                        label="Ride Start Time"
                        name="rideScheduledStartTimestamp"
                        rules={[{ required: true, message: "Please select the ride start time" }]}
                    >
                        <DatePicker
                            onChange={(value) => handleInputChange("rideScheduledStartTimestamp", value)}
                            showTime
                            placeholder="Pick the Ride start time"
                        />
                    </Form.Item>


                    <Form.Item
                        name="carType"
                        label="Type"
                        rules={[{ required: true, message: 'Please select Type!' }]}>
                        <Select placeholder="select your type" onChange={(value) => handleInputChange("carType", value)}>
                            {carsNames.map((type, index) => (
                                <Option key={index} value={type.type}>
                                    {type.type}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Remark"
                        name="remark"
                    >
                        <Input placeholder="Enter the car model" onChange={(e) => handleInputChange("remark", e.target.value)} />
                    </Form.Item>
                                              
                    {isEditMode && (
                        <Form.Item
                            name="rideStatus"
                            label="Ride Status"
                            rules={[{ required: true, message: 'Please select Ride Status!' }]}>
                            <Select placeholder="Select your car status" onChange={(value) => handleInputChange("rideStatus", value)}>
                                <Option value="accepted">Accepted</Option>
                                <Option value="completed">Completed</Option>
                                <Option value="cancelled">Cancelled</Option>
                                <Option value="expired">Expired</Option>
                                <Option value="requested">Requested</Option>
                                <Option value="ontrip">On-Trip</Option>
                                <Option value="ended">Ended</Option>
                                <Option value="arrived">Arrived</Option>
                            </Select>
                        </Form.Item>
                    )}

                    {isEditMode && (
                        <Form.Item
                            label="Payment Mode"
                            name="paymentMode"
                            rules={[{ required: true, message: 'Please Enter the Payment' }]}
                        >
                            <Input placeholder="Enter the Payment" onChange={(e) => handleInputChange("paymentMode", e.target.value)} />
                        </Form.Item>
                    )}

                    {isEditMode && (
                        <Form.Item
                            label="Fare"
                            name="fare"
                            rules={[{ required: true, message: 'Please Enter the Fare' }]}
                        >
                            <Input placeholder="Enter the Payment" onChange={(e) => handleInputChange("fare", e.target.value)} />
                        </Form.Item>
                    )}

                    {isEditMode && (
                        <Form.Item
                            label="Tax"
                            name="tax"
                            rules={[{ required: true, message: 'Please Enter the Tax' }]}
                        >
                            <Input placeholder="Enter the Tax" onChange={(e) => handleInputChange("tax", e.target.value)} />
                        </Form.Item>
                    )}


                    <Form.Item style={{ marginTop: '40px', marginLeft: '150px' }}>
                        <Button type="danger" onClick={handleCancel}>
                            cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {isEditMode ? "Update" : "Create"}
                        </Button>
                    </Form.Item>

                </Form>

            </Modal>
        </Card>
    )
}

export default Ride;